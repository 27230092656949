<script lang="ts" setup>
import { ref, computed, getCurrentInstance } from "vue";
import ResourcesRelationship from "@/components/iam/ResourcesRelationship.vue";
import { iamType, addRelationship, removeRelationship } from "@/services/api/iam.api";

const props = defineProps({
  userId: undefined,
  groupId: undefined,
  projectId: undefined,
  roleId: undefined,
});

const refreshKey = ref(0);

const accessSelection = computed(() => {
  let access = {};
  if (props.userId) {
    access = { domain: iamType.User, id: props.userId };
  } else if (props.groupId) {
    access = { domain: iamType.UserGroup, id: props.groupId };
  } else if (props.resourceId) {
    access = { domain: iamType.Resource, id: props.resourceId };
  } else if (props.projectId) {
    access = { domain: iamType.Project, id: props.projectId };
  } else if (props.roleId) {
    access = { domain: iamType.Role, id: props.roleId };
  }
  return access;
});

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function onAddRelationship(selection) {
  console.log("AccessResources doAddRelationship ", selection);
  const response = await addRelationship(accessSelection.value.domain, accessSelection.value.id, selection);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Toewijzen van de relatie is mislukt! " + response.error);
    }
  }
}

async function onRemoveRelationship(id) {
  console.log("AccessResources removeRelationship ", id);
  const response = await removeRelationship(id);
  if (response) {
    if (!response.error) {
      refreshKey.value++;
    } else {
      proxy.$toaster.error("Verwijder van de gebruikers aan een groep is mislukt! " + response.error);
    }
  }
}
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <resources-relationship
      @add-relationship="onAddRelationship"
      allow-remove="true"
      @remove-relationship="onRemoveRelationship"
      :refreshKey="refreshKey"
      :viaAccess="accessSelection"
    ></resources-relationship>
  </v-container>
</template>
